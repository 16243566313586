<style scoped>
  .w992-1400{
    display: none;
  }
  .isShow{
    display: block;
  }
  /*@media (min-width: 992px) {*/
    /*.isShow{*/
      /*display: block;*/
    /*}*/
  /*}*/
  /*@media (min-width: 1400px) {*/

  /*}*/
  @media screen and (min-width:992px) and (max-width:1400px){
    .w992-1400{display: block;}
    .isShow{display: none;}
  }
</style>
<script>
    function getChartColorsArray(colors){
        colors = JSON.parse(colors);
        return colors.map(function (value) {
            var newValue = value.replace(" ", "");
            if (newValue.indexOf(",") === -1) {
                var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
                if (color) {
                    color = color.replace(" ", "");
                    return color;
                }
                else return newValue;
            } else {
                var val = value.split(',');
                if (val.length == 2) {
                    var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                    rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
                    return rgbaColor;
                } else {
                    return newValue;
                }
            }
        });
    }

    import {Swiper,SwiperSlide} from "swiper/vue";
    import "swiper/swiper-bundle.css";
    import flatPickr from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";
    import Layout from "../../layouts/main.vue";
    import { CountTo } from "vue3-count-to";
    import {UsersIcon,} from '@zhuowenli/vue-feather-icons'
    import {xsview,xsstat,kcstat,cthd10,xsrk} from "@/utils/allApi";
    export default {
        components: {
            Layout,
            Swiper,
            SwiperSlide,
            flatPickr,
            CountTo,
            UsersIcon,
        },
        data() {
            return {
                all:"",
                cthd10Val:"",
                xsTotal:"",
                box1:"",
                box2:"",
                box3:"",
                box4:"",
                kcstatVal:"",
                xsrkVal:"",
            };
        },
        computed:{
        },
        mounted() {
            this.isiPadFun();
            xsview().then(res=>{
                this.all = res.data;
                this.xsTotal = res.data.xsTotal;
                let done = '';
                if(this.xsTotal.curYearTotal<this.xsTotal.year){
                    done = 100
                }else{
                    done = this.xsTotal.year/this.xsTotal.curYearTotal*100
                }
                this.box1 = {
                    series: [done],
                    chartOptions: {
                        chart: {
                            height: 350,
                            type: "radialBar",
                            toolbar: {
                                show: false,
                            },
                        },
                        plotOptions: {
                            radialBar: {
                                startAngle: -135,
                                endAngle: 225,
                                hollow: {
                                    margin: 0,
                                    size: "70%",
                                    image: undefined,
                                    imageOffsetX: 0,
                                    imageOffsetY: 0,
                                    position: "front",
                                },
                                track: {
                                    strokeWidth: "67%",
                                    margin: 0, // margin is in pixels
                                },

                                dataLabels: {
                                    show: true,
                                    name: {
                                        offsetY: -10,
                                        show: true,
                                        color: "#888",
                                        fontSize: "17px",
                                    },
                                    value: {
                                        formatter: function (val) {
                                            return parseInt(val)+'%';
                                        },
                                        color: "#111",
                                        fontSize: "36px",
                                        show: true,
                                    },
                                },
                            },
                        },
                        fill: {
                            type: "gradient",
                            gradient: {
                                shade: "dark",
                                type: "horizontal",
                                shadeIntensity: 0.5,
                                gradientToColors: getChartColorsArray('["--vz-success"]'),
                                inverseColors: true,
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 100],
                            },
                        },
                        stroke: {
                            lineCap: "round",
                        },
                        labels: ["已完成"],
                    },
                }
                this.box2 = {
                    series: [{
                        name: "",
                        data: res.data.yearTotal.data,
                    }, ],
                    chartOptions: {
                        chart: {
                            height: 350,
                            type: "bar",
                            events: {
                                click: function () {
                                    // console.log(chart, w, e)
                                },
                            },
                        },
                        colors:'#49abe0',
                        // colors: getChartColorsArray('["--vz-success"]'),
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                dataLabels: {
                                    position: "top", // top, center, bottom
                                },
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },

                        grid: {
                            borderColor: "#f1f1f1",
                        },
                        xaxis: {
                            categories: res.data.yearTotal.list,
                            labels: {
                                style: {
                                    fontSize: "12px",
                                },
                            },
                        },
                        fill: {
                            gradient: {
                                shade: "light",
                                type: "horizontal",
                                shadeIntensity: 0.25,
                                gradientToColors: undefined,
                                inverseColors: true,
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [50, 0, 100, 100],
                            },
                        },

                    },
                }
            })
            xsrk().then(res=>{
                this.xsrkVal = res.data
            })
            cthd10().then(res=>{
                this.cthd10Val = res.data;
            })
            xsstat().then(res=>{
                this.box3 = {
                    series: [
                        {
                            name: "",
                            data: res.xs15day[1],
                        },
                    ],
                    chartOptions: {
                        chart: {
                            height: 350,
                            type: "line",
                        },
                        stroke: {
                            width: 7,
                            curve: "smooth",
                        },
                        xaxis: {
                            // type: "datetime",
                            categories: res.xs15day[0],
                            tickAmount: 10,
                        },
                        fill: {
                            type: "gradient",
                            gradient: {
                                shade: "dark",
                                gradientToColors: ["#5fd0f3"],
                                shadeIntensity: 1,
                                type: "horizontal",
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 100, 100, 100],
                            },
                        },
                        markers: {
                            size: 4,
                            colors: getChartColorsArray('["--vz-success"]'),
                            strokeColors: "#fff",
                            strokeWidth: 2,
                            hover: {
                                size: 7,
                            },
                        },
                    },
                }
                this.box4 = {
                    series: [
                        {
                            name: "",
                            data: res.xs12month[1],
                        },
                    ],
                    chartOptions: {
                        chart: {
                            height: 350,
                            type: "line",
                        },
                        stroke: {
                            width: 7,
                            curve: "smooth",
                        },
                        xaxis: {
                            // type: "datetime",
                            categories: res.xs12month[0],
                            tickAmount: 10,
                        },
                        fill: {
                            type: "gradient",
                            gradient: {
                                shade: "dark",
                                gradientToColors: ["#5fd0f3"],
                                shadeIntensity: 1,
                                type: "horizontal",
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 100, 100, 100],
                            },
                        },
                        markers: {
                            size: 4,
                            colors: getChartColorsArray('["--vz-success"]'),
                            strokeColors: "#fff",
                            strokeWidth: 2,
                            hover: {
                                size: 7,
                            },
                        },
                    },
                }
            })
            kcstat().then(res=>{
                this.kcstatVal = res.data
            })
        },
        methods: {
        }

    };
</script>

<template>
  <Layout>
    <div class="row">
      <div :class="isiPad?'col-lg-12':'col-lg-6'">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">{{$t('home1')}}</h4>
          </div>
          <div class="card-body" >
            <div class="row" style="min-height: 350px;align-content: center;">
              <div class="col-lg-5 flex-center" style="text-align: center">
                <div v-if="xsTotal">
                  <h1 class="fs-36" style="font-weight: bold">
                    $<count-to style="font-size: 42px;" :startVal='0' :endVal='xsTotal.year' :duration='1000'></count-to>
                    <!--{{xsTotal.year}}-->
                  </h1>
                  <!--<p class="badge  fs-16 px-3 py-1" >-->
                  <p class="badge fs-16 px-3 py-1" :class="xsTotal.year-xsTotal.lastYear>=0?'bg-success':'bg-danger'">
                    <!--<i class="ri-arrow-down-s-line fs-13 align-middle me-1" ></i>-->
                    <i class="fs-24 align-middle me-1" :class="xsTotal.year-xsTotal.lastYear>=0?'ri-arrow-drop-up-line':'ri-arrow-drop-down-line'"></i>
                    $
                    <!--{{xsTotal.lastYear}}-->
                    <count-to :startVal='0' :endVal='xsTotal.lastYear' :duration='1000'></count-to>
                    ({{((xsTotal.year-xsTotal.lastYear)/xsTotal.lastYear*100).toFixed(2)}}%)
                  </p>
                </div>
              </div>
              <div class="flex-center col-lg-2">
                <img src="@/assets/wine.png" style="max-width: 87px;width: 100%;max-height: 305px;margin: 0 auto" alt="" >
              </div>
              <div class="col-lg-5"  style="text-align: center">
                <apexchart v-if="box1"
                        class="apex-charts"
                        height="280"
                        dir="ltr"
                        :series="box1.series"
                        :options="box1.chartOptions"
                ></apexchart>
                <h1 style="font-size: 16px;font-weight: 700">
                  {{$t('home2')}}$
                  <!--{{xsTotal.curYearTotal}}-->
                  <count-to  :startVal='0' :endVal='xsTotal.curYearTotal' :duration='1000'></count-to>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div :class="isiPad?'col-lg-12':'col-lg-6'">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">{{$t('home3')}}</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <apexchart v-if="box2"
                    class="apex-charts"
                    height="350"
                    dir="ltr"
                    :series="box2.series"
                    :options="box2.chartOptions"
            ></apexchart>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <div class="row">
      <div :class="isiPad?'col-lg-12':'col-lg-6'">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">{{$t('home4')}}</h4>
          </div>
          <div class="card-body ">
            <div class="table-responsive table-card mb-1">
              <table class="table  align-middle">
                <thead class="text-muted table-light">
                <tr>
                  <th scope="col" style="min-width: 120px">{{$t('home5')}}</th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('home6')}}</th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('home7')}}</th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('home8')}}</th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('home9')}}</th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('home71')}}</th>
                  <th scope="col" style="min-width: 150px;text-align: right">{{$t('home72-1')}}</th>
                  <th scope="col" style="min-width: 150px;text-align: right">{{$t('home72-2')}}</th>
                  <!--<th scope="col" style="min-width: 120px;text-align: right">{{$t('home10')}}</th>-->
                  <!--<th scope="col" style="min-width: 120px;text-align: right">{{$t('home11')}}</th>-->
                </tr>
                </thead>
                <tbody >
                <tr v-for="(res,index) in all.day" :class="index==all.day.length-1?'table-active':''" :key="index">
                  <td>{{res.channel}}</td>
                  <th scope="col" style="text-align: right">{{res.curTotal}}</th>
                  <th scope="col" style="text-align: right">{{res.curTotalCb}}</th>
                  <th scope="col" style="text-align: right">{{res.oldTotal}}</th>
                  <th scope="col" style="text-align: right">{{res.oldTotalCb}}</th>
<!--                  <th scope="col" style="text-align: right" :class="res.z>=0?'link-success':'link-danger'">{{res.z}}</th>-->
                  <th scope="col" style="text-align: right" >{{res.z}}</th>
                  <th scope="col" style="text-align: right" >{{res.cz}}</th>
                  <th scope="col" style="text-align: right" >{{res.cz}}</th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>

      <div :class="isiPad?'col-lg-12':'col-lg-6'">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">{{$t('home12')}}</h4>
          </div>
          <div class="card-body ">
            <div class="table-responsive table-card mb-1">
              <table class="table  align-middle">
                <thead class="text-muted table-light">
                  <tr>
                    <th scope="col" style="min-width: 120px">{{$t('home5')}}</th>
                    <th scope="col" style="min-width: 120px;text-align: right">{{$t('home13')}}</th>
                    <th scope="col" style="min-width: 120px;text-align: right">{{$t('home14')}}</th>
                    <th scope="col" style="min-width: 120px;text-align: right">{{$t('home15')}}</th>
                    <th scope="col" style="min-width: 120px;text-align: right">{{$t('home16')}}</th>
                    <!--<th scope="col" style="min-width: 120px;text-align: right">{{$t('home10')}}</th>-->
                    <!--<th scope="col" style="min-width: 120px;text-align: right">{{$t('home11')}}</th>-->
                  </tr>
                </thead>
                <tbody >
                <tr v-for="(res,index) in all.month" :class="index==all.month.length-1?'table-active':''" :key="index">
                  <td>{{res.channel}}</td>
                  <th scope="col" style="text-align: right">{{res.curTotal}}</th>
                  <th scope="col" style="text-align: right">{{res.curTotalCb}}</th>
                  <th scope="col" style="text-align: right">{{res.oldTotal}}</th>
                  <th scope="col" style="text-align: right">{{res.oldTotalCb}}</th>
                  <!--<th scope="col" style="text-align: right" :class="res.z>=0?'link-success':'link-danger'">{{res.z}}</th>-->
                  <!--<th scope="col" style="text-align: right" :class="res.cz>=0?'link-success':'link-danger'">{{res.cz}}</th>-->
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <div class="row" >

      <div :class="isiPad?'col-lg-12':'col-lg-12'">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">{{$t('home12-1')}}</h4>
          </div>
          <div class="card-body ">
            <div class="table-responsive table-card mb-1">
              <table class="table  align-middle">
                <thead class="text-muted table-light">
                  <tr>
                    <th scope="col" style="min-width: 120px">{{$t('home5')}}</th>
                    <th scope="col" style="min-width: 120px;text-align: right">{{$t('home13-1')}}</th>
                    <th scope="col" style="min-width: 120px;text-align: right">{{$t('home14-1')}}</th>
                    <th scope="col" style="min-width: 120px;text-align: right">{{$t('home15')}}</th>
                    <th scope="col" style="min-width: 120px;text-align: right">{{$t('home16')}}</th>
                    <!--<th scope="col" style="min-width: 120px;text-align: right">{{$t('home10')}}</th>-->
                    <!--<th scope="col" style="min-width: 120px;text-align: right">{{$t('home11')}}</th>-->
                  </tr>
                </thead>
                <tbody >
                <tr v-for="(res,index) in cthd10Val.month" :class="index==cthd10Val.month.length-1?'table-active':''" :key="index">
                  <td>{{res.channel}}</td>
                  <th scope="col" style="text-align: right">{{res.curTotal}}</th>
                  <th scope="col" style="text-align: right">{{res.curTotalCb}}</th>
                  <th scope="col" style="text-align: right">{{res.oldTotal}}</th>
                  <th scope="col" style="text-align: right">{{res.oldTotalCb}}</th>
                  <!--<th scope="col" style="text-align: right" :class="res.z>=0?'link-success':'link-danger'">{{res.z}}</th>-->
                  <!--<th scope="col" style="text-align: right" :class="res.cz>=0?'link-success':'link-danger'">{{res.cz}}</th>-->
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>

    <div class="row" >

      <div :class="isiPad?'col-lg-12':'col-lg-6'">
        <div class="card">
          <!--<div class="card-header justify-content-between d-flex align-items-center">-->
            <!--<h4 class="card-title">{{$t('home12-1')}}</h4>-->
          <!--</div>-->
          <div class="card-body ">
            <div class="table-responsive table-card mb-1">
              <table class="table  align-middle">
                <thead class="text-muted table-light">
                <tr>
                  <th scope="col" style="min-width: 120px"></th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('Elite Cru Club')}}</th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('Grand Cru Club')}}</th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('Premier Cru Club')}}</th>
                </tr>
                </thead>
                <tbody >
                <tr v-for="(res,index) in cthd10Val.club" :class="index==cthd10Val.club.length-1?'table-active':''" :key="index">
                  <td v-if="index==0">{{$t('home82')}}</td>
                  <td v-if="index==1">{{$t('home83')}}</td>
                  <td v-if="index==2">{{$t('home84')}}</td>
                  <td v-if="index==3">{{$t('home85')}}</td>
                  <td v-if="index==4">{{$t('home86')}}</td>
                  <td v-if="index==5">{{$t('home87')}}</td>
                  <th scope="col" style="text-align: right">{{res[0]}}</th>
                  <th scope="col" style="text-align: right">{{res[1]}}</th>
                  <th scope="col" style="text-align: right">{{res[2]}}</th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <div :class="isiPad?'col-md-12':'col-md-6'">
        <div class="card">
          <div class="card-body ">
            <div class="table-responsive table-card mb-1">
              <table class="table  align-middle">
                <thead class="text-muted table-light">
                <tr>
                  <th scope="col" style="min-width: 120px;">{{$t('time')}}</th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('add-user21')}}{{$t('stock-table4')}}</th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('t-deposit')}}{{$t('stock-table4')}}</th>
                </tr>
                </thead>
                <tbody >
                <tr v-for="(res,index) in xsrkVal" :class="index==xsrkVal.length-1?'table-active':''" :key="index">
                  <th style="font-weight: 100">{{res[0]}}</th>
                  <th scope="col" style="text-align: right">{{res[1]}}</th>
                  <th scope="col" style="text-align: right">{{res[2]}}</th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>

      <!--end col-->
    </div>
    <!--end col-->

    <div class="row">
      <!--    xsrk-->
      <div :class="isiPad?'col-lg-12':'col-lg-6'">
        <div class="card">
          <!--<div class="card-header justify-content-between d-flex align-items-center">-->
          <!--<h4 class="card-title">{{$t('home12-1')}}</h4>-->
          <!--</div>-->
          <div class="card-body ">
            <div class="table-responsive table-card mb-1">
              <table class="table  align-middle">
                <thead class="text-muted table-light">
                <tr>
                  <th scope="col" style="min-width: 120px"></th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('t-wineRoom')}}</th>
                  <th scope="col" style="min-width: 120px;text-align: right">{{$t('home81')}}</th>
                </tr>
                </thead>
                <tbody >
                <tr v-for="(res,index) in cthd10Val.jiedai" :class="index==cthd10Val.jiedai.length-1?'table-active':''" :key="index">
                  <td v-if="index==0">{{$t('home88')}}</td>
                  <td v-if="index==1">{{$t('home89')}}</td>
                  <td v-if="index==2">{{$t('home90')}}</td>
                  <th scope="col" style="text-align: right">{{res[0]}}</th>
                  <th scope="col" style="text-align: right">{{res[1]}}</th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>

      <div v-if="false" :class="isiPad?'col-md-6':'col-md-3'" >
        <div class="card card-animate">
          <div class="card-body" style="background: #0ab29d;">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium mb-0" style="color: #ffffff">{{$t('home73')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to class="fs-36" style="color: #ffffff" :startVal='0' :endVal='kcstatVal.customer' :duration='5000'></count-to>
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title  rounded-circle fs-2" style="background-color: #fff;color:#0ab29d">
                  <i class="las la-user-friends"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <div v-if="false" :class="isiPad?'col-md-6':'col-md-3'" >
        <div class="card card-animate">
          <div class="card-body" style="background: #0ab29d;">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium mb-0" style="color: #ffffff">{{$t('home74')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to class="fs-36" style="color: #ffffff" :startVal='0' :endVal='kcstatVal.club' :duration='5000'></count-to>
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title rounded-circle fs-2" style="background-color: #fff;color:#0ab29d">
                  <i class="las la-user-friends"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <div v-if="false" :class="isiPad?'col-md-6':'col-md-3'" >
        <div class="card card-animate">
          <div class="card-body" style="background: #0ab29d;">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium mb-0" style="color: #ffffff">{{$t('home75')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to class="fs-36" style="color: #ffffff" :startVal='0' :endVal='kcstatVal.zhuceNum' :duration='5000'></count-to>
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title rounded-circle fs-2" style="background-color: #fff;color:#0ab29d">
                  <i class="las la-user-friends"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <div v-if="false" :class="isiPad?'col-md-6':'col-md-3'" >
        <div class="card card-animate">
          <div class="card-body" style="background: #0ab29d;">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium mb-0" style="color:#fff">{{$t('home76')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to class="fs-36" style="color:#fff" :startVal='0' :endVal='kcstatVal.tuidingNum' :duration='5000'></count-to>
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title rounded-circle fs-2" style="background-color: #fff;color:#0ab29d">
                  <i class="las la-user-friends"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>

      <div v-if="false" :class="isiPad?'col-md-6':'col-md-3'" >
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">{{$t('home77')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="kcstatVal.benyuexsnum">
                  <count-to class="fs-36" :startVal='0' :endVal='kcstatVal.benyuexsnum[0]' :duration='5000'></count-to>
                  (<count-to class="fs-36" :startVal='0' :endVal='kcstatVal.benyuexsnum[1]' :duration='5000'></count-to>)
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <i class=" las la-wine-bottle"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <div v-if="false" :class="isiPad?'col-md-6':'col-md-3'" >
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">{{$t('home78')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="kcstatVal.jinnianxsNum">
                  <count-to class="fs-36" :startVal='0' :endVal='kcstatVal.jinnianxsNum[0]' :duration='5000'></count-to>
                  (<count-to class="fs-36" :startVal='0' :endVal='kcstatVal.jinnianxsNum[1]' :duration='5000'></count-to>)
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <i class=" las la-wine-bottle"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <div v-if="false" :class="isiPad?'col-md-6':'col-md-3'" >
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">{{$t('home79')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to class="fs-36" :startVal='0' :endVal='kcstatVal.jinnianNum' :duration='5000'></count-to>
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <i class=" las la-wine-bottle"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <div v-if="false" :class="isiPad?'col-md-6':'col-md-3'" >
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">{{$t('home80')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to class="fs-36" :startVal='0' :endVal='kcstatVal.quniankucun' :duration='5000'></count-to>
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <i class=" las la-wine-bottle"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>


      <div v-if="false" :class="isiPad?'col-md-6':'col-md-3'" >
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">{{$t('home19')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to class="fs-36" :startVal='0' :endVal='kcstatVal.productNumber' :duration='5000'></count-to>
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <i class=" las la-wine-bottle"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <div :class="isiPad?'col-md-6':'col-md-3'" >
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">{{$t('home20')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to class="fs-36" :startVal='0' :endVal='kcstatVal.count' :duration='5000'></count-to>
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <i class="las la-home"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <div v-if="false" :class="isiPad?'col-md-6':'col-md-3'">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">{{$t('home21')}}</p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to class="fs-36" :startVal='0' :endVal='kcstatVal.pcount' :duration='5000'></count-to>
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <i class="las la-home"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
      <div :class="isiPad?'col-md-6':'col-md-3'">
        <div class="card card-animate" style="background: #405189;">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium mb-0 badge" >{{$t('home22')}} </p>
                <h2 class="ff-secondary fw-semibold fs-24" style="color: #fff;margin-top: 38px">
                  $
                  <count-to class="fs-24" :startVal='0' :endVal='kcstatVal.price' :duration='5000'></count-to>
                  （<count-to class="fs-24" :startVal='0' :endVal='kcstatVal.cbprice' :duration='5000'></count-to>）
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded-circle fs-2">
                  <i class="las la-dollar-sign"></i>
                </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card-->
      </div>
    </div>

  </Layout>
</template>